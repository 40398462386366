<script lang="ts" setup>
import { watch, withDefaults, defineProps } from 'vue'
import { cloneDeep } from '@/helpers'

interface Props {
    items: any
    optionLabelField: string
    modelValue?: any
    placeholder?: string
    searchFields?: string[]
    inputClass?: string
    isLoading?: boolean
    disabled?: boolean
    validation?: any
}

const props = withDefaults(defineProps<Props>(), {
    searchFields: () => ['name'],
    modelValue: null
})

const localItems = ref(
    props.items?.length ? cloneDeep(props.items) : []
)

watch(
    () => props.items,
    (newVal: any) => {
        if (newVal?.length) {
            localItems.value = cloneDeep(newVal)
        }
    },
    { deep: true }
)

interface Emit {
    (e: 'update:modelValue', data: any): void

    (e: 'onSelect', data: any): void

    (e: 'show'): void

    (e: 'hide'): void
}

const emit = defineEmits<Emit>()

const isOpen = ref(false)
const searchTermInput = ref()

function toggleDropdownList() {
    if (props.disabled) return
    if (isOpen.value) {
        emit('hide')
    } else {
        emit('show')
    }
    isOpen.value = !isOpen.value
}

watchEffect(() => {
    if (isOpen.value) {
        searchTermInput.value.focus()
    }
})
const uuid = new Date().getTime() + Math.random()
// const baseDropdownRef = ref({})
// el => (baseDropdownRef[uuid] = el)
const baseDropdownRef = ref()
useDetectOutsideClick(baseDropdownRef, () => {
    isOpen.value = false
})

const selectedItemLabel = ref(
    props.modelValue && props.modelValue[props.optionLabelField]
        ? props.modelValue[props.optionLabelField]
        : ''
)
watch(
    () => props.modelValue,
    newVal => {
        selectedItemLabel.value =
            newVal && newVal[props.optionLabelField]
                ? newVal[props.optionLabelField]
                : ''
        emit('update:modelValue', newVal)
        emit('onSelect', newVal)
    }
)

function selectItem(item: any) {
    emit('update:modelValue', item)
    emit('onSelect', item)
    selectedItemLabel.value = item[props.optionLabelField]
    if (props.validation?.$errors) {
        props.validation.$touch()
    }
    isOpen.value = false
}

const searchTerm = ref()
watch(
    () => searchTerm.value,
    newVal => {
        if (!props.optionLabelField) {
            console.error(
                "Can't start search cause optionLabelField is undefined"
            )
        }

        if (props.items?.length) {
            localItems.value = props.items.filter((item: any) => {
                return props.searchFields.some(field => {
                    const fieldValue = item[field]
                    return (
                        fieldValue &&
                        String(fieldValue)
                            ?.toLowerCase()
                            ?.includes(newVal?.toLowerCase())
                    )
                })
            })
        }
    }
)

const hasValidationError = computed(
    () => props.validation?.$errors?.length >= 1
)

const validationMessage = computed(
    () => props.validation.$errors[0]?.$message
)
</script>

<template>
    <div
        :id="uuid"
        ref="baseDropdownRef"
        style="position: relative; width: 100%"
    >
        <div
            class="filter__input base-dropdown__selected-label"
            :class="[
                { 'filter__input--opened': isOpen },
                props.inputClass,
                { 'invalid-fieldset': hasValidationError },
                { disabled: disabled }
            ]"
            style="
                flex-grow: 1;
                cursor: pointer;
                user-select: none;
                text-align: left;
            "
            @click="toggleDropdownList"
        >
            {{ selectedItemLabel || placeholder }}
            <div
                v-if="hasValidationError"
                class="validation-message-wrapper"
            >
                <InfoCircleIcon
                    class="info-circle"
                    :error="hasValidationError"
                />
                <div class="validation-message">
                    {{ validationMessage }}
                </div>
            </div>
            <div class="base-dropdown-icon-wrapper">
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 12 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    alt="icon"
                    :style="{
                        transform: `rotate(${
                            isOpen ? '180deg' : '0deg'
                        })`,
                        cursor: 'pointer'
                    }"
                >
                    <path
                        d="M6.06634 5.59798C5.9742 5.59839 5.88291 5.58157 5.79781 5.54851C5.71272 5.51546 5.63553 5.46683 5.57079 5.40548L0.895759 1.03048C0.802515 0.90282 0.760438 0.748447 0.776948 0.594588C0.793458 0.440729 0.867494 0.297267 0.985999 0.189506C1.1045 0.0817444 1.25987 0.0166057 1.42468 0.00557798C1.5895 -0.00544976 1.75318 0.0383422 1.88687 0.12923L6.06634 4.01423L10.2458 0.12923C10.3776 0.0492629 10.5343 0.0130195 10.6907 0.0263086C10.8471 0.0395977 10.9943 0.10165 11.1085 0.202519C11.2228 0.303389 11.2975 0.437234 11.3208 0.582603C11.3441 0.727972 11.3146 0.876447 11.2369 1.00423L6.56189 5.37923C6.49956 5.44535 6.42345 5.49885 6.33824 5.53647C6.25303 5.57408 6.1605 5.59501 6.06634 5.59798Z"
                        fill="#888888"
                    />
                </svg>
            </div>
        </div>

        <div
            class="filter__input-dropdown filter-dropdown"
            :class="{ 'is-active': isOpen }"
        >
            <div class="filter-dropdown__search" @click.stop>
                <input
                    ref="searchTermInput"
                    v-model="searchTerm"
                    type="text"
                />
                <button
                    name="filter-dropdown-search"
                    class="filter-dropdown__search-btn"
                >
                    <SvgoSearch />
                </button>
            </div>
            <div class="filter-dropdown__list">
                <template v-if="isLoading">
                    <AppSpiner style="margin: 50px auto" />
                </template>
                <template v-else>
                    <div
                        class="filter-dropdown__item"
                        :class="{
                            'filter-dropdown__item--active':
                                !selectedItemLabel
                        }"
                        @click.stop="selectItem('')"
                    >
                        {{ placeholder }}
                    </div>
                    <div
                        v-for="(item, index) in localItems"
                        :key="item?.id || index"
                        class="filter-dropdown__item"
                        :class="{
                            'filter-dropdown__item--active':
                                (item?.id &&
                                    item.id === modelValue?.id) ||
                                (modelValue &&
                                    item[optionLabelField] ===
                                        modelValue[optionLabelField])
                        }"
                        @click.stop="selectItem(item)"
                    >
                        {{ item[optionLabelField] }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.disabled {
    color: #888888;
}

.validation-message-wrapper {
    display: flex;
    position: absolute;
    right: 33px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
}

.validation-message {
    position: relative;
    margin-right: 8px;
    background: #f54305;
    padding: 8px 20px;
    color: #fff;
    font-size: 12px;
    border-radius: 8px;
    display: none;
    right: 14px;

    &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -10px;
        //margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #f54305;
    }
}

.info-circle {
    position: absolute;
    cursor: pointer;
    right: 0;

    &:hover + .validation-message {
        display: block;
    }
}

.invalid-fieldset {
    border: 1px solid #f54305;
}
</style>
